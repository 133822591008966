import {HttpClient, HttpEventType, HttpHeaders, HttpProgressEvent} from '@angular/common/http'
import {inject, Injectable, signal} from '@angular/core'
import {IDocument, IFileUpload, IPkg, IPutRequestResult} from '@sparbanken-syd/user-documents-backend'
import {filter, finalize, map, Observable, switchMap, tap} from 'rxjs'
import {environment} from '../../environments/environment'
import {ProgressIndicatorService} from './progress-indicator.service'

export interface IFileProgress {
  /**
   * Status
   */
  status: 'progress' | 'completed'
  /**
   * The actual upload result
   */
  document?: IFileUpload
  // If present a percentage of the file upload
  message?: number
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  public createdDocuments$ = signal<IDocument[]>([])
  public savedDocuments$ = signal<IDocument[]>([])
  public movedDocuments$ = signal<IDocument[]>([])

  public packageId$ = signal<string | undefined>(undefined)

  private http = inject(HttpClient)

  private readonly progressService = inject(ProgressIndicatorService)

  /**
   * Upload a new document. It starts with creating an upload link, then uploads
   * the "data" part to S3.
   * @param document - The document as read from disk + some additional meta data.
   */
  public uploadDocumentData(document: IFileUpload): Observable<IFileProgress> {
    const headers = new HttpHeaders({'Content-Type': document.contentType})
    const data = {
      contentType: document.contentType,
      name: document.name,
      size: document.size
    }
    const url = `${environment.documentUrl}/user/packages`

    // Make a `PUT` request to create the document and obtain an upload URL
    return this.http.put<IPutRequestResult>(url, data, {headers})
      .pipe(
        switchMap((r: IPutRequestResult) => {
          // Set document data with the response from the request
          document.id = r.id
          document.packageId = r.packageId
          document.viewUrl = r.viewUrl
          // Make a `PUT` request to actually upload the file
          return this.http.put(r.putUrl, document.documentData, {
            headers,
            reportProgress: true,
            observe: 'events'
          })
        }),
        filter(event =>
          event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response),

        map(event => {
          if (event.type === HttpEventType.Response) {
            // Some trickery ...
            const doc: any = document
            delete doc.documentData
            this.createdDocuments$().push(doc)
            return {status: 'completed'}
          }
          const progress = event as HttpProgressEvent
          return {
            status: 'progress',
            message: Math.round(100 * (progress.loaded / (progress.total || 1)))
          }
        })
      )
  }

  public getDocuments(): Observable<IPkg> {
    const url = `${environment.documentUrl}/user/packages`
    return this.http.get<IPkg>(url).pipe(
      tap(this.updateFromPackage))
  }

  public deleteDocument(id: string): Observable<void> {
    const url = `${environment.documentUrl}/user/packages/${this.packageId$()}/any/${id}`
    return this.http.delete<void>(url)
  }

  public moveAndSaveDocuments(): Observable<IPkg> {
    const url = `${environment.documentUrl}/user/packages/move`
    this.progressService.start()
    const ids = this.createdDocuments$().map((d: IDocument) => d.id)
    return this.http.put<IPkg>(url, {packageId: this.packageId$()}).pipe(
      tap(pkg => this.updateFromPackage(pkg, ids)),
      finalize(() => {
        this.progressService.stop()
      })
    )
  }

  private updateFromPackage = (pkg: IPkg, moved: string[] = []): void => {
    this.packageId$.set(pkg.packageId)
    this.createdDocuments$.set(pkg.createdDocuments)
    this.savedDocuments$.set(pkg.savedDocuments)
    this.movedDocuments$.set(pkg.savedDocuments.filter(d => moved.includes(d.id)))
  }
}
