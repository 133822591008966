import {Component, inject} from '@angular/core'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'
import { BankIdComponent } from '@sparbanken-syd/sparbanken-syd-bankid'

@Component({
    selector: 'spb-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: true,
    imports: [BankIdComponent]
})
export class LoginComponent {
  protected readonly environment = environment
  public configService = inject(ConfigService)
}
