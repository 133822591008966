import {Component, EventEmitter, inject, Output} from '@angular/core'
import {ProgressIndicatorService} from '../../../services/progress-indicator.service'
import {AsyncPipe} from '@angular/common'
import {MatIcon} from '@angular/material/icon'

@Component({
  selector: 'spb-add-another',
  templateUrl: './add-another.component.html',
  styleUrls: ['../../big-icon.scss'],
  standalone: true,
  imports: [MatIcon, AsyncPipe]
})
export class AddAnotherComponent {
  @Output() fileSelected: EventEmitter<boolean> = new EventEmitter<boolean>()
  public progressService = inject(ProgressIndicatorService)

}
