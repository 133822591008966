<mat-card>
  <input #fileUpload (change)="onFileSelected($event.target)" accept=".txt, .pdf, .png, .jpg, jpeg, .doc, .docx, .rtf, .odt"
         multiple
         class="file-input" type="file"/>

  @if (documentService.createdDocuments$().length > 0) {
    <spb-add-another (fileSelected)=fileUpload.click()></spb-add-another>
  } @else {
    <div
      class="big-icon-holder"
      (click)="fileUpload.click()"
      (keyup.enter)="fileUpload.click()"
      (keydown.space)="fileUpload.click()"
      tabindex="0"
      role="button"
    >
      <mat-icon (click)="fileUpload.click()" class="material-symbols-outlined big-icon">
        note_add
      </mat-icon>
      <div class="big-icon-text">Tryck här för att ladda upp dokument</div>
    </div>
  }

  @if (progressIndicatorService.progressRunning$ | async) {
    <div class="loading-holder">
      <mat-progress-bar [mode]="secondProgressBar$() ? 'buffer' : 'determinate'"
                        [value]="progress$()"></mat-progress-bar>
    </div>
  }


  @for (document of documentService.createdDocuments$(); track document.id) {
    <spb-selected (remove)="deleteFile($event)"
                  [document]="document"></spb-selected>
  }


  @if (documentService.createdDocuments$().length > 0 &&
  (progressIndicatorService.progressRunning$ | async) === false) {
    <div>
      <button (click)="save()"
              color="primary"
              mat-raised-button>Skicka till Sparbanken Syd
      </button>
    </div>
  }
</mat-card>


