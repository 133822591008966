/**
 * Store some variables that we want to access
 * here and there
 */

import {InjectionToken} from '@angular/core'

/**
 * The routes are good to be consistent with.
 */
export const LOGIN_PATH = 'autentisering'
export const DOCUMENT_PATH = 'dokument'


/**
 * The injection tokens
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')
export const FILE_READER = new InjectionToken<FileReader>('fileReader')