import {Component, OnInit} from '@angular/core'
import {TryAgainComponent} from './try-again/try-again.component'
import {MatCard} from '@angular/material/card'

@Component({
  selector: 'spb-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  standalone: true,
  imports: [TryAgainComponent, MatCard]
})
export class ErrorComponent implements OnInit {

  /**
   * Error messages
   */
  public errorMessage: string = ''

  public ngOnInit() {
    this.errorMessage = history.state.errorMessage
  }

}
