import {Component, inject} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import {AsyncPipe} from '@angular/common'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {DocumentComponent} from './document/info/document.component'
import {RouterOutlet} from '@angular/router'
import {HeaderComponent} from './components/header/header.component'
import {DisclaimerComponent} from './document/disclaimer/disclaimer.component'

@Component({
    selector: 'spb-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
  imports: [HeaderComponent, RouterOutlet, DocumentComponent, ThemeModule, AsyncPipe, DisclaimerComponent]
})
export class AppComponent {
  public version = version.version
  public configService = inject(ConfigService)

}
