import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose} from '@angular/material/dialog'

interface Data {
  fileName: string
  title: string
}

@Component({
  selector: 'spb-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  standalone: true,
  imports: [
    MatDialogActions,
    MatButton,
    MatDialogClose]
})
export class AreYouSureComponent {
  public readonly data = inject<Data>(MAT_DIALOG_DATA)
}
