import {Routes} from '@angular/router'
import {DoneComponent} from './done/done.component'
import {ErrorComponent} from './error/error.component'
import {HomeComponent} from './home/home.component'
import {UploadFileComponent} from './upload-file/upload-file.component'

export const documentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent,
        children: [
          {
            path: 'add',
            component: UploadFileComponent,
            outlet: 'item'
          },
          {
            path: 'fel',
            component: ErrorComponent,
            outlet: 'item'
          },
          {
            path: 'klar',
            component: DoneComponent,
            outlet: 'item'
          },
          {
            path: 'fel',
            component: ErrorComponent,
            outlet: 'item'
          }
        ]
      }


    ]
  }


]
