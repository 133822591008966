<mat-card>
  <p>
    Välkommen till Sparbanken Syds dokumentportal, där du kan komplettera med
    dokument som banken har efterfrågat i ditt ärende. När du använder denna
    tjänst behandlar och lagrar vi de personuppgifter du överför genom portalen
    i enlighet med våra dataskyddsprinciper.
  </p>

  <p>Läs mer här:
    <a
      href="https://www.sparbankensyd.se/om-sparbanken-syd/kundskydd/integritet-och-sekretess-gdpr/"
      target="_blank">
      Så här tar vi hand om dina personuppgifter.
    </a>
  </p>
</mat-card>