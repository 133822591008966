import {Component, inject} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {IDocument} from '@sparbanken-syd/user-documents-backend'
import {filter, switchMap} from 'rxjs'
import {ConfigService} from '../../services/config.service'
import {DocumentService} from '../../services/document.service'
import {AreYouSureComponent} from '../are-you-sure/are-you-sure.component'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatCard} from '@angular/material/card'
import {SelectedComponent} from '../../components/selected/selected.component'

@Component({
  selector: 'spb-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss',
  standalone: true,
  imports: [MatIcon, MatButton, MatCard, SelectedComponent]
})
export class DocumentComponent {

  public configService = inject(ConfigService)
  public documentService = inject(DocumentService)
  private readonly dialog = inject(MatDialog)
  private readonly snackBar = inject(MatSnackBar)

  public deleteFile(id: string, fileName: string): void {
    this.dialog.open(AreYouSureComponent, {
      data: {fileName, title: 'Radera fil'}
    }).afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.documentService.deleteDocument(id))
      )
      .subscribe({
        next: () => {
          this.documentService.savedDocuments$.set(
            this.documentService.savedDocuments$().filter((d: IDocument) => d.id !== id))
          this.snackBar.open('Filen har raderats', '', {duration: 2000})
        }
      })
  }
}
