<mat-card>
  @if (documentService.savedDocuments$().length > 0) {
    <div>
      <h3>Uppladdade dokument</h3>
      @for (document of documentService.savedDocuments$(); track document.id) {
        <spb-selected
          color="#d7e9f5"
          [document]="document"
          [removable]="!document.isPermanent"
          (remove)="deleteFile(document.id, document.name)"
        ></spb-selected>
      }
    </div>
  }

  <div>
    <button (click)="configService.logout()" color="primary"
            mat-raised-button>
      Avsluta / ny person
    </button>
  </div>
</mat-card>
