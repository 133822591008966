<div [style.padding.px]="20">
  <h3>{{ data.title }}</h3>
  <div>
    <p>
      Är du säker på att du vill radera "{{ data.fileName }}"?
    </p>
    <div [style.margin-left.px]="-10" matDialogActions>
      <button
        [matDialogClose]="true"
        color="primary"
        mat-raised-button>Ja
      </button>
      <button [matDialogClose]="null" mat-raised-button>Avbryt</button>
    </div>
  </div>
</div>