<div [style.background-color]="color()" class="file-item">
  <spb-document-name
    [name]="document().name"
    [url]="document().viewUrl!"
  ></spb-document-name>
  @if (removable()) {
    <mat-icon (click)="remove.emit(document().id)"
              class="material-symbols-sharp remove">close
    </mat-icon>
  }
</div>
