import {Component} from '@angular/core'
import {MatCard} from '@angular/material/card'

@Component({
    selector: 'spb-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrl: './disclaimer.component.scss',
  imports: [
    MatCard
  ],
    standalone: true
})
export class DisclaimerComponent {

}
