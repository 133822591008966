import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {APP_INITIALIZER, ApplicationConfig} from '@angular/core'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation, withViewTransitions} from '@angular/router'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {FILE_READER, LOCAL_STORAGE} from './application/types'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: FILE_READER, useClass: FileReader},
    {provide: LOCAL_STORAGE, useValue: localStorage},
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => configService.checkStatus,
      deps: [ConfigService],
      multi: true
    },
    provideRouter(routes,
      withViewTransitions(),
      withHashLocation()),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}