import {Component, input} from '@angular/core'

@Component({
  selector: 'spb-document-name',
  standalone: true,
  imports: [],
  templateUrl: './document-name.component.html',
  styleUrl: './document-name.component.scss'
})
export class DocumentNameComponent {
  public name = input.required<string>()
  public url = input.required<string>()
  protected readonly document = document
}
