@if ((progressService.progressRunning$ | async) === false) {
  <div
    [style.margin-bottom.px]="10"
    class="big-icon-holder"
    role="button"
    (click)="fileSelected.emit(true)"
    (keyup.enter)="fileSelected.emit(true)"
    tabindex="0"
  >
    <mat-icon class="material-symbols-outlined big-icon">note_add</mat-icon>
    <div class="big-icon-text"
    >Lägg till fler dokument
    </div>
  </div>
}
