import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {DOCUMENT_PATH, LOGIN_PATH} from './application/types'
import {LoginComponent} from './components/login/login.component'
import {documentRoutes} from './document/document.routes'

export const routes: Routes = [
  {
    path: DOCUMENT_PATH,
    children: documentRoutes,
    canActivate: [authGuard]
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH
  }
]
