import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {
  public progressRunning$: Observable<boolean>
  private sub$ = new BehaviorSubject<boolean>(false)

  constructor() {
    this.progressRunning$ = this.sub$.asObservable()
  }

  public start(): void {
    this.sub$.next(true)
  }

  public stop(): void {
    this.sub$.next(false)
  }
}
