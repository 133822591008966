import {AsyncPipe} from '@angular/common'
import {HttpErrorResponse} from '@angular/common/http'
import {Component, inject, OnInit, signal} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatProgressBar} from '@angular/material/progress-bar'
import {Router} from '@angular/router'
import {IDocument, IFileUpload} from '@sparbanken-syd/user-documents-backend'
import {catchError, concatMap, filter, map, NEVER, tap} from 'rxjs'
import {DOCUMENT_PATH} from '../../application/types'
import {Uploader} from '../../application/uploader'
import {DocumentService} from '../../services/document.service'
import {AddAnotherComponent} from './add-another/add-another.component'
import {MatCard} from '@angular/material/card'
import {SelectedComponent} from '../../components/selected/selected.component'

@Component({
  selector: 'spb-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['../big-icon.scss', './upload-file.component.scss'],
  standalone: true,
  imports: [
    AddAnotherComponent,
    MatIcon,
    MatProgressBar,
    MatButton,
    AsyncPipe,
    MatCard,
    SelectedComponent
  ]
})
export class UploadFileComponent extends Uploader implements OnInit {
  /**
   * Tracks upload progress
   */
  public progress$ = signal<number>(0)
  public secondProgressBar$ = signal<boolean>(false)

  public documentService = inject(DocumentService)
  private readonly router = inject(Router)

  constructor() {
    super()
  }

  public ngOnInit(): void {
    /**
     * The result is where we get a document to upload, see base class
     */
    this.result$.pipe(
      concatMap((data: IFileUpload) => {
          this.progressIndicatorService.start()
          return this.documentService.uploadDocumentData(data)
        }
      ),
      // Map of HttpEvents and handle them based on their type
      map(event => {
        if (event.status === 'progress') {
          // Here, we update 'progress' representing upload progress in percentage
          this.progress$.set(event.message!)
        }
        return event
      }),
      // Filter out only events of type Response signaling that the upload is complete
      filter(event => event.status === 'completed')
    ).subscribe({
      next: () => {
        // Set progress to 0 when the upload is complete (prepare for the next upload)
        this.progress$.set(0)
        this.progressIndicatorService.stop()
      },
      error: (e) => {
        // Reset progress and handle any errors that occur during the upload
        this.resetAfterError(e)
      }
    })
  }

  public deleteFile(id: string): void {
    this.documentService.deleteDocument(id).subscribe()
    this.documentService.createdDocuments$.set(
      this.documentService.createdDocuments$().filter((d: IDocument) => d.id !== id)
    )
  }

  public save() {
    this.secondProgressBar$.set(true)
    this.documentService.moveAndSaveDocuments()
      .pipe(
        catchError((e) => {
          this.resetAfterError(e)
          return NEVER
        }),
        tap(() => {
          // If we do this in a tap, instead of subscribe
          // We can let the service clean up the progress.
          this.router.navigate([DOCUMENT_PATH, {outlets: {item: 'klar'}}]).then()
        })
      )
      .subscribe()
  }

  private resetAfterError(e: HttpErrorResponse) {
    const errorMessage = e.error.errorMessage || e.message
    this.progressIndicatorService.stop()
    this.progress$.set(0)
    // Pass the error message as a parameter to the next component
    this.router.navigate(
      [DOCUMENT_PATH, {outlets: {item: 'fel'}}],
      {state: {errorMessage}}).then()
  }
}
