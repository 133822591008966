<div class="text-container mat-elevation-z8 ">
  <h3>Välkommen till vår dokumentportal! </h3>
  <p>Här kan komplettera ditt ärende med
    dokument som banken har efterfrågat. För att ladda upp dokument behöver du
    först identifiera dig med BankID.
  </p>

  <spb-bankid
    mode="login"
    customButton="Öppna BankID"
    [bankIdUrl]="environment.authServiceUrl"
    [domain]="environment.domain"
    (accessToken)="configService.newLogin($event)"
  ></spb-bankid>
</div>

