import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)

  /**
   * Only send authorization bearer if endpoint is relevant
   */
  if (req.url.indexOf('/packages') !== -1 || req.url.indexOf('service') !== -1 || req.url.indexOf('/move') !== -1) {
    return configService.accessToken$.pipe(
      first(),
      switchMap(token => {
        if (!token) {
          return next(req)
        }
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
        return next(authReq)
      })
    )
  }

  /**
   * Else set no more headers
   */
  return next(req)
}
